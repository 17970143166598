var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      attrs: {
        width: "19",
        height: "19",
        viewBox: "0 0 19 19",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M5.66021 15.5004L5.51183 15.6451C5.26573 15.8912 4.86402 15.8912 4.61792 15.6451C4.37183 15.399 4.37183 14.9973 4.61792 14.7512L5.88459 13.4846C6.13068 13.2385 6.5324 13.2385 6.77849 13.4846L8.04516 14.7512C8.29125 14.9973 8.29125 15.399 8.04516 15.6451C7.79906 15.8912 7.39735 15.8912 7.15125 15.6451L6.92687 15.4207C6.99925 15.779 7.14764 16.0287 7.35392 16.1988C7.91487 16.6621 8.74364 16.4775 8.74364 16.4775C9.08745 16.4087 9.4204 16.6331 9.48916 16.9733C9.55792 17.3171 9.33354 17.6501 8.99335 17.7188C8.99335 17.7188 7.54211 17.9903 6.55049 17.176C6.14154 16.843 5.78325 16.3219 5.66021 15.5004Z",
          fill: _vm.isActive ? "#343A60" : "#FDFDFD",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M13.3399 3.49603L13.4883 3.35127C13.7344 3.10517 14.1361 3.10517 14.3822 3.35127C14.6283 3.59736 14.6283 3.99908 14.3822 4.24517L13.1155 5.51184C12.8694 5.75793 12.4677 5.75793 12.2216 5.51184L10.9549 4.24517C10.7088 3.99908 10.7088 3.59736 10.9549 3.35127C11.201 3.10517 11.6027 3.10517 11.8488 3.35127L12.0732 3.57565C12.0008 3.21736 11.8525 2.96765 11.6462 2.79755C11.0852 2.33432 10.2565 2.51889 10.2565 2.51889C9.91265 2.58765 9.5797 2.36327 9.51093 2.02308C9.44217 1.67927 9.66655 1.34632 10.0067 1.27755C10.0067 1.27755 11.458 1.00613 12.4496 1.82041C12.8586 2.15336 13.2168 2.67451 13.3399 3.49603Z",
          fill: _vm.isActive ? "#343A60" : "#FDFDFD",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M8.86667 10.7667C8.86667 11.8162 8.01619 12.6667 6.96667 12.6667H1.9C0.850476 12.6667 0 11.8162 0 10.7667V1.90002C0 0.850496 0.850476 2.00272e-05 1.9 2.00272e-05H6.96667C8.01619 2.00272e-05 8.86667 0.850496 8.86667 1.90002V10.7667Z",
          fill: _vm.isActive ? "#343A60" : "#FDFDFD",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M19 17.1C19 18.1495 18.1495 19 17.1 19H12.0333C10.9838 19 10.1333 18.1495 10.1333 17.1V8.23333C10.1333 7.18381 10.9838 6.33333 12.0333 6.33333H17.1C18.1495 6.33333 19 7.18381 19 8.23333V17.1Z",
          fill: "#717DC6",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }